export const standardRegions = [
  'us-east-1',
  'us-east-2',
  'us-west-2',
  'eu-west-1',
  'eu-central-1',
  'ap-south-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'us-gov-west-1',
];
